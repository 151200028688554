import { useCallback, useEffect, useState } from "react"
import { useLocation, navigate } from "@reach/router"
import { client } from "../graphql/client"

const generatePath = require("../helpers/generatePath")


const parseParentRef = (iteration = 0) => {
  if (iteration === 6) return ""
  return `parent->{ tabs { content { ..., ${parseParentRef(iteration + 1)} } } }`
}

const Preview = () => {
  const location = useLocation()
  const [previewPage, setPreviewPage] = useState(null)

  const generateGroqQuery = useCallback((id, type) => {
    switch (type) {
      case "blogPost":
      case "cs":
      case "resource":
      case "event": return `*[_id == "${id}"] {
        ...,
        tabs {
          content {
            ...,
            category->{...}
          }
        }
      }[0]`
      case "homepage":
      case "industry": return `*[_id == "${id}"][0]`
      case 'page': return `*[_id == "${id}"]{
        ...,
        tabs {
          content {
            ...,
            ${parseParentRef()}
          }
        }
      }[0]`
      default: return null
    }
  }, [])

  const getPageData = useCallback((id, type) => {
    const query = generateGroqQuery(id, type)

    client.fetch(query, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(document => {
      setPreviewPage(document)
    }).catch(error => {
      console.error(error)
    })
  }, [generateGroqQuery])

  useEffect(() => {
    const pathname = location.pathname.split('/').filter((v) => !!v)
    const type = pathname[pathname.length - 2]
    const id = pathname[pathname.length - 1]

    getPageData(id, type)
  }, [getPageData, location.pathname])

  useEffect(() => {
    if (!previewPage || typeof window === "undefined" || !previewPage) return null;
    const { category, ...page  } = previewPage

    if (previewPage._type === "homepage") {
      const [, market = ""] = previewPage._id.split("-")
      navigate(`/${market}`)
      return
    }

    if (category) page.tabs.content.category = category;

    const path = `/${generatePath(page)}`
    navigate(path)
  }, [previewPage])

  return null
}

export default Preview